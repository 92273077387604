const BASE_URL = "https://us-central1-clipsas-poll.cloudfunctions.net/api";
//const BASE_URL = "http://127.0.0.1:5001/clipsas-poll/us-central1/api";

const Routes = {
  answerQuestion: `${BASE_URL}/questions/answer`,
  getAllQuestions: `${BASE_URL}/questions/getQuestions`,
  getSingleQuestion: `${BASE_URL}/questions/single`,
  getAdvancedUser: `${BASE_URL}/users/advanced`,
  sendForgotPasswordLink: `${BASE_URL}/users/forgotPassword`,
  getTotalNumberOfUsersWhoCanVote: `${BASE_URL}/questions/totalNumberOfUsersWhoCanVote`,
};

export default Routes;
