import React, {
  ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
} from "react";

import {
  Auth,
  UserCredential,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { storageService, userService } from ".";
import StorageKeys from "../constants/storageKeys";
import axios from "axios";
import { IAdvancedUser } from "../constants/types";
import { useNavigate } from "react-router-dom";

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);
export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  sendPasswordResetEmail?: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  advancedUser: IAdvancedUser | null;
}

export const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [advancedUser, setAdvancedUser] = useState<IAdvancedUser | null>(null);

  function signUp(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  async function signOut() {
    storageService.cleanLocaleStorage();
    return await auth.signOut();
  }

  async function refreshIdToken() {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      storageService.saveToken(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      const advUser = await userService.getAdvancedUser(currentUser.uid);
      setAdvancedUser(advUser);
      storageService.setLocale(StorageKeys.advancedUser, advUser);
    }
  }

  const navigate = useNavigate();
  useEffect(() => {
    //function that firebase notifies you if a user is set
    const unsubsrcibe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          setUser(user);
          if (user) {
            setUser(user);
            refreshIdToken(); // Initial token refresh on user login
          } else {
            storageService.cleanLocaleStorage();
            setUser(null);
          }
        } else {
          storageService.cleanLocaleStorage();
          setUser(null);
          navigate("/login"); // Redirect to login page when user is not authenticated
        }
      } catch (error) {
        storageService.cleanLocaleStorage();
        setUser(null);
        navigate("/login");
      }
    });
    // Setup a timer to refresh the token before it expires
    const tokenRefreshTimer = setInterval(refreshIdToken, 50 * 60 * 1000); // Refresh token every 50 minutes

    return () => {
      unsubsrcibe();
      clearInterval(tokenRefreshTimer);
    };
  }, []);

  const values = {
    signUp,
    user,
    signIn,
    resetPassword,
    signOut,
    auth,
    advancedUser,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
