import {
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Container,
  RadioGroup,
  SimpleGrid,
  Text,
  Radio,
  Button,
  useToast,
  Box,
  Flex,
  Center,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IQuestion } from "../constants/types";
import { answerQuestion } from "../services/QuestionService";
import Countdown from "react-countdown";
import QuestionData from "../components/QuestionData";

function VoteJourney(props: {
  allQuestions: IQuestion[];
  removeQuestionFromActiveQuestions: (id: string, isExpired: boolean) => void;
  showStatQuestion: IQuestion | undefined;
}) {
  const [activeQuestion, setActiveQuestion] = useState<IQuestion>();
  const [answeredQuestions, setAnsweredQuestions] = useState<IQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState<IQuestion[]>([]);
  const [isAllQuestionsAnswered, setIsAllQuestionsAnswered] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [answerValue, setAnswerValue] = useState<string>();

  const toast = useToast();

  useEffect(() => {
    setAllQuestions((props.allQuestions as IQuestion[]) || []);
    if (props.allQuestions.length === 0) {
      setIsAllQuestionsAnswered(true);
    } else {
      setIsAllQuestionsAnswered(false);
      setActiveQuestion(props.allQuestions[0] as IQuestion);
    }
  }, [props.allQuestions]);

  // function progressCountdownPercentage(
  //   hours: number,
  //   minutes: number,
  //   seconds: number,
  //   endDate: Date
  // ) {
  //   const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
  //   const remainingTime = (endDate.getTime() - Date.now()) / 1000;
  //   const percentage = ((totalSeconds - remainingTime) / totalSeconds) * 100;
  //   return percentage;
  // }

  async function submitAnswer(answer?: string) {
    if (!answerValue) {
      toast({
        title: "Please select an answer.",
        description: "Please select an answer before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setAnswerLoading(true);
    const answerResp = await answerQuestion(activeQuestion?.id!, answer!);
    if (answerResp.success) {
      setAnswerLoading(false);
      setAnsweredQuestions([...answeredQuestions, activeQuestion!]);
      if (allQuestions.length === answeredQuestions.length + 1) {
        setIsAllQuestionsAnswered(true);
      }
      setActiveQuestion(allQuestions[answeredQuestions.length + 1]);
      setAnswerValue(undefined);
      toast({
        title: "Your vote has been submitted.",
        description: "Thanks for participating.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error occurred on submitting your vote.",
        description: answerResp.message || "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setAnswerLoading(false);
    }
  }

  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isAllQuestionsAnswered ? (
        props.showStatQuestion ? (
          <Container maxW="7xl">
            <QuestionData questionData={props.showStatQuestion} />
          </Container>
        ) : (
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="xl">
              Your vote has been registered
            </AlertTitle>
            <AlertDescription maxWidth="sm" fontSize={"lg"}>
              Thanks for participating.
            </AlertDescription>
          </Alert>
        )
      ) : (
        activeQuestion && (
          <Container maxW={"7xl"} mt={5}>
            <Box
              w={"100%"}
              borderWidth="1px"
              borderRadius="lg"
              p={6}
              boxShadow="xl"
            >
              <Heading as="h2" size="xl" textAlign="center" mt={4} mb={10}>
                {activeQuestion.questionText}
              </Heading>
              <RadioGroup>
                <SimpleGrid minChildWidth="120px" mt={2} gap={4}>
                  {activeQuestion.options.map((option: any, index) => (
                    <Button
                      key={index}
                      variant={
                        answerValue === option.title ? "solid" : "outline"
                      }
                      colorScheme={
                        answerValue === option.title ? "teal" : "gray"
                      }
                      onClick={() => setAnswerValue(option.title)}
                    >
                      {option.title}
                    </Button>
                  ))}
                </SimpleGrid>
              </RadioGroup>
              <Center>
                <Button
                  mt={4}
                  colorScheme="teal"
                  variant="outline"
                  isLoading={answerLoading}
                  isDisabled={answerLoading}
                  onClick={() => {
                    submitAnswer(answerValue);
                  }}
                >
                  Submit
                </Button>
              </Center>

              {/* <Text fontWeight="bold" fontSize="lg" mb={2}>
                  Progress: {answeredQuestions.length} / {allQuestions.length}
                </Text>
                <Progress value={progressValueasPercentage()} w="100%" /> */}
              {activeQuestion.countdownDate && (
                <Countdown
                  date={activeQuestion.countdownDate}
                  renderer={({ hours, minutes, seconds, completed }) => {
                    if (completed) {
                      props.removeQuestionFromActiveQuestions(
                        activeQuestion.id,
                        true
                      );
                      //setIsAllQuestionsAnswered(true);
                      // Render a completed state
                      return <Completionist />;
                    } else {
                      // Render a countdown
                      return (
                        <Flex
                          flexDirection={"column"}
                          alignItems="flex-start"
                          mt={8}
                        >
                          <Text fontWeight="bold" fontSize="lg" mb={2}>
                            Time Remaining:
                          </Text>
                          <Text
                            fontWeight="bold"
                            fontSize="lg"
                            color="gray.500"
                          >
                            {hours}:{minutes}:{seconds}
                          </Text>
                          {/* <Progress
                            value={progressCountdownPercentage(
                              hours,
                              minutes,
                              seconds,
                              new Date(activeQuestion.countdownDate!)
                            )}
                            w="100%"
                          /> */}
                        </Flex>
                      );
                    }
                  }}
                />
              )}
            </Box>
          </Container>
        )
      )}
    </>
  );
}

export default VoteJourney;
