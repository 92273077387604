import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { sendForgotPasswordLink } from "../services/UserService";

export const ForgotPassword = () => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSendResetLink() {
    setIsLoading(true);
    const linkResp = await sendForgotPasswordLink(email);
    if (linkResp.success) {
      toast({
        title: "Reset link sent",
        description: "Please check your email for the reset link",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setLinkSent(true);
    } else {
      toast({
        title: "Reset link failed",
        description: linkResp.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  }

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>Forgot Password</Heading>
            <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
              Enter your email address and we will send you a link to reset your
              password.
            </Text>
          </Stack>
        </Stack>
        <Box rounded="lg" bg="white" boxShadow="lg" p={{ base: "6", md: "8" }}>
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }}>
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
            </Stack>
            <Stack spacing="6">
              <Button
                colorScheme={"blue"}
                onClick={handleSendResetLink}
                isLoading={isLoading}
                isDisabled={linkSent}
              >
                Send Reset Link
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
