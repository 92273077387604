// Chakra imports
import {
  Avatar,
  Box,
  Card,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IAdvancedUser } from "../../constants/types";
import Information from "./Information";

export default function Banner(props: {
  banner: string;
  avatar: string;
  name: string;
  job: string;
  posts: number | string;
  followers: number | string;
  following: number | string;
  advancedUser: IAdvancedUser;
  [x: string]: any;
}) {
  const { banner, avatar, name, job, posts, followers, following, ...rest } =
    props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "#111C44 !important",
    "white !important"
  );
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card
      maxW={{ base: "100%", lg: "80%", md: "80%" }}
      mb={{ base: "0px", lg: "20px" }}
      alignItems="center"
      {...rest}
      pb="20px"
    >
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        name={props.name}
        bg="white"
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {props.advancedUser.name + " " + props.advancedUser.surname}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {props.advancedUser?.rightToVote
          ? "Has Right to Vote"
          : "Has No Right to Vote"}
      </Text>
      {/* <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Posts
          </Text>
        </Flex>
        <Flex mx="auto" me="60px" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Followers
          </Text>
        </Flex>
        <Flex mx="auto" alignItems="center" flexDirection="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Following
          </Text>
        </Flex>
      </Flex> */}
      <SimpleGrid columns={2} gap="20px" mt={4} pl={"10px"} pr={"10px"}>
        <Information
          boxShadow={cardShadow}
          title="E-Mail"
          value={props.advancedUser.email}
        />
        <Information
          boxShadow={cardShadow}
          title="Obedience"
          value={props.advancedUser.obedience}
        />
        <Information
          boxShadow={cardShadow}
          title="Obedience Vote Weight"
          value={props.advancedUser.obedienceVoteWeight}
        />
        {props.advancedUser.bigLodgeName && (
          <Information
            boxShadow={cardShadow}
            title="Big Lodge Name"
            value={props.advancedUser.bigLodgeName}
          />
        )}
        {props.advancedUser.areaLodgeName && (
          <Information
            boxShadow={cardShadow}
            title="Area Lodge Name"
            value={props.advancedUser.areaLodgeName}
          />
        )}
        {props.advancedUser.regionLodgeName && (
          <Information
            boxShadow={cardShadow}
            title="Region Lodge Name"
            value={props.advancedUser.regionLodgeName}
          />
        )}
        {props.advancedUser.lodgeName && (
          <Information
            boxShadow={cardShadow}
            title="Lodge Name"
            value={props.advancedUser.lodgeName}
          />
        )}
        {props.advancedUser.attachedUnit && (
          <Information
            boxShadow={cardShadow}
            title="Attached Unit"
            value={props.advancedUser.attachedUnit}
          />
        )}
        {props.advancedUser.attendeeTitle && (
          <Information
            boxShadow={cardShadow}
            title="Attendee Title"
            value={props.advancedUser.attendeeTitle}
          />
        )}
        {props.advancedUser.country && (
          <Information
            boxShadow={cardShadow}
            title="Country"
            value={props.advancedUser.country}
          />
        )}
        {props.advancedUser.city && (
          <Information
            boxShadow={cardShadow}
            title="City"
            value={props.advancedUser.city}
          />
        )}
        {props.advancedUser.phoneNumber && (
          <Information
            boxShadow={cardShadow}
            title="Phone Number"
            value={props.advancedUser.phoneNumber}
          />
        )}
      </SimpleGrid>
    </Card>
  );
}
