import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";

import { AuthProvider } from "./services/AuthProvider";
import Layout from "./Layout";
import theme from "./theme/theme";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </ChakraProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
