import axios from "axios";
import Routes from "../constants/constants";
import { IQuestion } from "../constants/types";

export async function answerQuestion(questionId: string, answer: string) {
  try {
    const url = Routes.answerQuestion + "/" + questionId;
    const response = await axios.post(url, {
      answer,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getQuestions(): Promise<IQuestion[]> {
  try {
    const response = await axios.get(Routes.getAllQuestions);
    const responseJson = response.data;
    return responseJson.success ? (response.data.data as IQuestion[]) : [];
  } catch (error) {
    return [];
  }
}

export async function getSingleQuestion(questionId: string) {
  try {
    const url = Routes.getSingleQuestion + "/" + questionId;
    const response = await axios.get(url);
    const responseJson = response.data;
    return responseJson;
  } catch (error) {
    return [];
  }
}

export async function getTotalNumberOfUsersWhoCanVote(): Promise<{
  totalNumberOfUsersWhoCanVote: number;
  totalNumberOfUsersWhoCanVoteWithProxies: number;
}> {
  try {
    const response = await axios.get(
      `${Routes.getTotalNumberOfUsersWhoCanVote}`
    );
    const responseJson = await response.data;
    return responseJson.data as {
      totalNumberOfUsersWhoCanVote: number;
      totalNumberOfUsersWhoCanVoteWithProxies: number;
    };
  } catch (error) {
    return {
      totalNumberOfUsersWhoCanVote: 0,
      totalNumberOfUsersWhoCanVoteWithProxies: 0,
    };
  }
}
