import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PasswordField } from "../components/Login/PasswordField";
import { useAuth } from "../services/AuthProvider";

export const Login = () => {
  const { signIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();
  const toast = useToast();
  async function signInWithEmailAndPassword() {
    setFormSubmitting(true);
    try {
      await signIn(email, password);
      navigate("/");
      // history.push(DASHBOARD_PAGE_PATH)
    } catch (error: unknown) {
      let errorMessage = "error.unknown";
      if (typeof error === "string") {
        errorMessage = error.toUpperCase();
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setFormError(errorMessage);
      setFormSubmitting(false);
    }
  }
  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>
              Log in to your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg-surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </FormControl>
              <PasswordField
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button
                variant="link"
                colorScheme="blue"
                size="sm"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot password?
              </Button>
            </HStack>
            <Stack spacing="6">
              <Button
                variant="primary"
                onClick={signInWithEmailAndPassword}
                disabled={formSubmitting}
                isLoading={formSubmitting}
              >
                Sign in
              </Button>
            </Stack>
            <br />
            {formError && (
              <Alert variant="error" status="error">
                {formError}
              </Alert>
            )}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
