import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Container,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IQuestion } from "../../constants/types";
import {
  answerQuestion,
  getSingleQuestion,
} from "../../services/QuestionService";

const Vote = () => {
  const [question, setQuestion] = useState<IQuestion>();
  const [isLoading, setIsLoading] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
  const [answerValue, setAnswerValue] = useState<string>();

  let { id } = useParams();

  async function getQuestionById() {
    setIsLoading(true);
    // GET Question id from URL
    const questionId = id;
    if (!questionId) return;
    // GET Question by ID
    const questionResp = await getSingleQuestion(questionId);
    setQuestion(questionResp.data as IQuestion);
    setIsLoading(false);
  }

  async function submitAnswer(answer: string) {
    setAnswerLoading(true);
    const answerResp = await answerQuestion(id!, answer);
    if (answerResp.success) {
      setIsQuestionAnswered(true);
      setAnswerLoading(false);
    } else {
      setAnswerLoading(false);
    }
  }

  useEffect(() => {
    getQuestionById();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isQuestionAnswered ? (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Vote submitted!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Thanks for submitting your vote.
          </AlertDescription>
        </Alert>
      ) : (
        question && (
          <Container maxW="3xl" mt={5}>
            {/* <Center flexDirection="column">
                        <Heading>Correct Answer for {quiz.title}</Heading>
                        <Text mt={4}>{quiz.description}</Text>
                    </Center> */}
            <Box borderWidth="1px" borderRadius="lg" p={6} boxShadow="xl">
              <Text>{question.questionText}</Text>
              <RadioGroup>
                <SimpleGrid minChildWidth="120px" mt={2}>
                  {question.options.map((option: any, index) => (
                    <Radio
                      value={option.title}
                      key={index}
                      onChange={(e) => {
                        setAnswerValue(e.target.value);
                      }}
                    >
                      {option.title}
                    </Radio>
                  ))}
                </SimpleGrid>
              </RadioGroup>

              <Button
                mt={4}
                colorScheme="teal"
                variant="outline"
                isLoading={answerLoading}
                isDisabled={answerLoading}
                onClick={() => {
                  if (!answerValue) return;
                  submitAnswer(answerValue);
                }}
              >
                Submit
              </Button>
            </Box>
          </Container>
        )
      )}
    </>
  );
};

export default Vote;
