import axios from "axios";
import Routes from "../constants/constants";
import { IAdvancedUser } from "../constants/types";

export async function getAdvancedUser(
  userId: string
): Promise<IAdvancedUser | null> {
  try {
    const url = Routes.getAdvancedUser + "/" + userId;
    const response = await axios.get(url);
    const responseJson = response.data;
    if (responseJson.success) {
      return responseJson.user as IAdvancedUser;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function sendForgotPasswordLink(
  email: string
): Promise<{ success: boolean; message: string }> {
  try {
    const url = Routes.sendForgotPasswordLink;
    const response = await axios.post(url, { email });
    const responseJson = response.data;
    return {
      success: responseJson.success ?? false,
      message: "Forgot password link sent to your email",
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message ?? "Error sending forgot password link",
    };
  }
}
