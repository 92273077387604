import axios from "axios";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { ForgotPassword } from "./screens/ForgotPassword";
import { Login } from "./screens/Login";
import MainPage from "./screens/MainPage";
import Profile from "./screens/Profile";
import Vote from "./screens/Vote/Vote";
import { storageService } from "./services";
import { AuthContext } from "./services/AuthProvider";

function Layout() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    if (authContext.user) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [authContext.user]);

  if (authContext.user) {
    return (
      <div id="layout-main">
        <Navbar
          onOpen={() => {}}
          logoText={"CLIPSAS e-Vote System"}
          brandText={"CLIPSAS e-Vote System"}
          secondary={false}
          message={"Welcome to CLIPSAS e-Vote System"}
          fixed={true}
        />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/quiz" element={<Index />} /> */}
          <Route path="/vote/:id" element={<Vote />} />
          {/* <Route path="/vote-journey" element={<VoteJourney />} /> */}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div id="layout-main">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to={"/login"} />} />
        </Routes>
      </div>
    );
  }
}

export default Layout;
