import StorageKeys from "../constants/storageKeys";

export const saveToken = (token: string) => {
  localStorage.setItem(StorageKeys.token, token);
};

export const getUserJson = () =>
  JSON.parse(localStorage.getItem(StorageKeys.user) || "{}");

export const getAdvancedUserJson = () =>
  JSON.parse(localStorage.getItem(StorageKeys.advancedUser) || "{}");

export const getToken = () => localStorage.getItem(StorageKeys.token);

export const cleanLocaleStorage = () => localStorage.clear();

export const setLocale = (key: any, el: any) => {
  localStorage.setItem(key, JSON.stringify(el));
};
