import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { IAnswer, IQuestion } from "../constants/types";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  getDocs,
} from "firebase/firestore";
import { AuthContext, useAuth } from "../services/AuthProvider";
import VoteJourney from "./VoteJourney";
import QuestionData from "../components/QuestionData";

const MainPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeQuestions, setActiveQuestions] = useState<IQuestion[]>([]);
  const [showStatQuestion, setShowStatQuestion] = useState<IQuestion>();

  const { user } = useAuth();
  const authContext = useContext(AuthContext);

  const db = getFirestore();
  const toast = useToast();

  function removeQuestionFromActiveQuestions(id: string, isExpired: boolean) {
    isExpired &&
      toast({
        title: "Question expired",
        description: "This question has expired",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    const filteredQuestions = activeQuestions.filter((q) => q.id !== id);
    setActiveQuestions(filteredQuestions);
  }

  useEffect(() => {
    const questionsRef = collection(db, "questions");

    const questionsQuery = query(questionsRef, where("isActive", "==", true));

    const unsubscribe = onSnapshot(questionsQuery, async (querySnapshot) => {
      setIsLoading(true);

      const userAnswersRef = collection(db, "answers");
      const userAnswersQuery = query(
        userAnswersRef,
        where("answeredBy", "==", user?.uid)
      );

      const userAnswersQuerySnapshot = await getDocs(userAnswersQuery);
      const userAnswers = userAnswersQuerySnapshot.docs.map((doc) =>
        doc.data()
      ) as IAnswer[];

      const data = querySnapshot.docs.map((doc) => doc.data()) as IQuestion[];

      // Filter questions by their countdowndate if question has countdown date and countdown date is not expired
      const filteredQuestions = data.filter((q) => {
        if (userAnswers.map((a) => a.questionId).includes(q.id)) {
          return false;
        } else {
          if (q.countdownDate) {
            const countdownDate = new Date(q.countdownDate);
            const now = new Date();
            if (countdownDate > now) {
              return true;
            } else {
              removeQuestionFromActiveQuestions(q.id, false);
              return false;
            }
          } else {
            return true;
          }
        }
      });
      setActiveQuestions(filteredQuestions);

      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const questionsRef = collection(db, "questions");

    const statsQuery = query(questionsRef, where("showStats", "==", true));

    const unsubscribe = onSnapshot(statsQuery, async (querySnapshot) => {
      setIsLoading(true);

      const data = querySnapshot.docs.map((doc) => doc.data()) as IQuestion[];

      setShowStatQuestion(data.length > 0 ? data[0] : undefined);
      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <Box h={"100%"}>
      <Heading>
        <title>QuizApp</title>
        <link rel="icon" href="/favicon.ico" />
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : activeQuestions.length === 0 ? (
        showStatQuestion ? (
          <Container maxW="20xl">
            <QuestionData questionData={showStatQuestion} />
          </Container>
        ) : (
          <Box h={"100%"}>
            <Flex
              h={"100%"}
              flexDirection="column"
              justifyContent="center"
              alignItems={"center"}
            >
              <SearchIcon height={30} />
              <Text textAlign={"center"} fontSize="lg">
                There is no active vote session, please wait until an admin
                activates a new session
              </Text>
            </Flex>
          </Box>
        )
      ) : (
        <Container maxW="6xl" width={"100%"} mt="80px">
          {activeQuestions.length > 0 &&
          !authContext.advancedUser?.rightToVote ? (
            <Flex
              flexDirection={"row"}
              justifyContent={"center"}
              height={"400px"}
            >
              <Center>
                <Text fontSize="2xl" fontWeight="bold">
                  You don't have the right to vote
                </Text>
              </Center>
            </Flex>
          ) : (
            <Flex flexDirection={"row"} justifyContent={"center"}>
              <Box
                height="100%"
                width={"100%"}
                as="button"
                textAlign="start"
                m={2}
              >
                {/* {generateQuizCard(activeQuestions[0])} */}
                <VoteJourney
                  allQuestions={activeQuestions}
                  removeQuestionFromActiveQuestions={
                    removeQuestionFromActiveQuestions
                  }
                  showStatQuestion={showStatQuestion}
                />
              </Box>
            </Flex>
          )}
        </Container>
      )}
    </Box>
  );
};

export default MainPage;
