import {
  Box,
  Center,
  Flex,
  Progress,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  doc as firestoreDoc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";
import { IAdvancedUser, IQuestion } from "../constants/types";
import { getTotalNumberOfUsersWhoCanVote } from "../services/QuestionService";
import Card from "./common/Card";

function QuestionData(props: { questionData: IQuestion }) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textFontSize = useBreakpointValue({
    base: "xl",
    md: "xxl",
    lg: "2xl",
    xl: "3xl",
  });
  const [totalNumberOfUsersObj, setTotalNumberOfUsersObj] = useState<{
    totalNumberOfUsersWhoCanVoteWithProxies: number;
    totalNumberOfUsersWhoCanVote: number;
  }>({
    totalNumberOfUsersWhoCanVoteWithProxies: 0,
    totalNumberOfUsersWhoCanVote: 0,
  });
  const [question, setQuestion] = useState<IQuestion>(
    props.questionData || null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState<string[]>([]);
  const [pieChartData, setPieChartData] = useState<number[]>([]);
  const [obedienceBasedPieChartData, setObedienceBasedPieChartData] = useState<
    number[]
  >([]);
  const [totalVotesLoading, setTotalVotesLoading] = useState(true);
  const [obedienceBasedAnswers, setObedienceBasedAnswers] = useState<number[]>(
    []
  );
  const [obedienceBasedIsLoading, setObedienceBasedIsLoading] =
    useState<boolean>(true);

  function getPercentageOfValueByTotal(value: number, total: number): string {
    return ((value / total) * 100).toFixed(2) || "0";
  }

  async function handleObedienceVoteWeightStatistics(answersDict: {
    [key: number]: string[];
  }) {
    setObedienceBasedIsLoading(true);
    const obedienceBasedAnswersDict: {
      [key: number]: number;
    } = {};
    const userDict: {
      [key: string]: number;
    } = {};
    let users: IAdvancedUser[] = [];
    const userIds = Object.values(answersDict).flat();

    // Get all users by userIds without onSnapshot
    const db = getFirestore();
    // const q = query(collection(db, "users"), where("id", "in", userIds));
    const promises = userIds.map(async (userId) => {
      const docRef = firestoreDoc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        users.push(docSnap.data() as IAdvancedUser);
      }
    });
    await Promise.all(promises);

    // console.log(q);
    // const querySnapshot = await getDocs(q);
    users.forEach((user) => {
      userDict[user.id] = user.obedienceVoteWeight;
    });
    // Now set the obedienceBasedAnswersDict by multiplying the answersDict with userDict values
    question.options.forEach((option, index) => {
      obedienceBasedAnswersDict[index] = 0;
    });
    Object.keys(answersDict).forEach((answerDictKey: string) => {
      const key = parseInt(answerDictKey);
      let calculatedUserIds: string[] = [];
      // the reduce function takes two parameters: a function, and an initial value
      obedienceBasedAnswersDict[key] = answersDict[key].reduce(
        // the function takes two parameters: the accumulator and the current value
        (acc, curr) => {
          if (!calculatedUserIds.includes(curr)) {
            calculatedUserIds.push(curr);
            return acc + userDict[curr];
          } else {
            // dont add the obedienceVoteWeight if the user has already been calculated
            return acc;
          }
        },
        // the initial value is 0
        0
      );
    });
    setObedienceBasedPieChartData(
      Object.values(obedienceBasedAnswersDict).map((answer) => answer)
    );
    setObedienceBasedAnswers(
      Object.values(obedienceBasedAnswersDict).map((answer) => answer)
    );
    setObedienceBasedIsLoading(false);
  }

  async function getTotalNumberOfUsersCanVote() {
    setTotalVotesLoading(true);
    // let totalNumberOfUsersWhoCanVoteWithProxies = 0;
    // let totalNumberOfUsersWhoCanVote = 0;
    // console.log("now get total number of users who can vote");
    const data = await getTotalNumberOfUsersWhoCanVote();
    // const db = getFirestore();
    // const q = query(collection(db, "users"), where("rightToVote", "==", true));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   const userData = doc.data() as IAdvancedUser;
    //   totalNumberOfUsersWhoCanVote += 1;
    //   totalNumberOfUsersWhoCanVoteWithProxies += userData.voteWeight ?? 1;
    // });
    // setTotalNumberOfUsersObj({
    //   totalNumberOfUsersWhoCanVoteWithProxies,
    //   totalNumberOfUsersWhoCanVote,
    // });
    setTotalNumberOfUsersObj({
      totalNumberOfUsersWhoCanVoteWithProxies:
        data.totalNumberOfUsersWhoCanVoteWithProxies,
      totalNumberOfUsersWhoCanVote: data.totalNumberOfUsersWhoCanVote,
    });
    setTotalVotesLoading(false);
  }

  const pieChartOptions: any = {
    labels: question.options.map((option) => option.title),
    // 6 different colors
    colors: [
      "#4318FF",
      "#6AD2FF",
      "#EFF4FB",
      "#FF0000",
      "#ffff00",
      "#00ff00",
    ].slice(0, question.options.length),
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [
        "#4318FF",
        "#6AD2FF",
        "#EFF4FB",
        "#FF0000",
        "#ffff00",
        "#00ff00",
      ].slice(0, question.options.length),
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  useEffect(() => {
    setQuestion(props.questionData);
    const db = getFirestore();
    const q = query(
      collection(db, "answers"),
      where("questionId", "==", props.questionData.id)
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      setIsLoading(true);
      setObedienceBasedIsLoading(true);
      getTotalNumberOfUsersCanVote();
      const answersDict: {
        [key: number]: string[];
      } = {};
      question.options.forEach((option, index) => {
        answersDict[index] = [];
      });
      const answers: any = [];
      // for (const doc of querySnapshot.docs) {
      //   const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
      //   // Get Answered user data from users collection based on answeredBy
      //   const userAnswered = (await getDoc(docRef)).data() as IAdvancedUser;
      //   console.log("userAnswered", userAnswered);
      //   answersDict[doc.data().answerIndex] = [
      //     ...(answersDict[doc.data().answerIndex] || []),
      //     // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
      //     ...Array(userAnswered.voteWeight || 1).fill(doc.data().answeredBy),
      //   ];
      //   console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
      //   answers.push(doc.data().answeredBy);
      // }
      // make the above for loop parallel
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
          // Get Answered user data from users collection based on answeredBy
          const userAnswered = (await getDoc(docRef)).data() as IAdvancedUser;
          answersDict[doc.data().answerIndex] = [
            ...(answersDict[doc.data().answerIndex] || []),
            // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
            ...Array(userAnswered.voteWeight || 1).fill(doc.data().answeredBy),
          ];
          answers.push(doc.data().answeredBy);
        })
      );
      setAnswers(answers);
      setPieChartData(
        Object.values(answersDict).map((answer) => answer.length)
      );
      setIsLoading(false);
      handleObedienceVoteWeightStatistics(answersDict);
    });

    // //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [props.questionData]);

  return (
    <SimpleGrid
      columns={{ base: 1, md: 1 }}
      gap="20px"
      mb="20px"
      mt={{ base: "60px", md: "80px", xl: "50px" }}
      overflowX={"hidden"}
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="start"
        w="100%"
        mb="8px"
      >
        {
          <Text
            color={textColor}
            fontSize={{
              base: "lg",
              md: "xl",
              lg: "2xl",
              xl: "3xl",
            }}
            fontWeight="600"
            mt="4px"
          >
            Question: {question.questionText}
          </Text>
        }
        {!isLoading &&
          (totalVotesLoading ? (
            <Spinner />
          ) : (
            <Stack alignItems={"flex-start"}>
              <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
                {/* Number of vote casts : {answers.length} */}
                {`Users : ${answers.length}/${
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote
                } - % ${getPercentageOfValueByTotal(
                  answers.length,
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote
                )}`}
              </Text>
              <Progress
                colorScheme="brand"
                backgroundColor={"gray.200"}
                size="sm"
                value={answers.length}
                max={totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote}
                borderRadius="10px"
                w="100%"
              />
              <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
                {/* Number of vote casts : {answers.length} */}
                {`Users (Including Proxies) : ${pieChartData.reduce(
                  (a, b) => a + b,
                  0
                )}/${
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                } - % ${getPercentageOfValueByTotal(
                  pieChartData.reduce((a, b) => a + b, 0),
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                )}`}
              </Text>
              <Progress
                colorScheme="brand"
                backgroundColor={"gray.200"}
                size="sm"
                value={pieChartData.reduce((a, b) => a + b, 0)}
                max={
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                }
                borderRadius="10px"
                w="100%"
              />
              {/* <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
              Number Of Users Who Can Vote: {totalNumberOfUsersWhoCanVote}
            </Text> */}
            </Stack>
          ))}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px">
        {isLoading ? (
          <Flex
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
            <Text fontSize="xl" color="black" fontWeight="700" mb="5px">
              Loading...
            </Text>
          </Flex>
        ) : (
          <Card p="20px" alignItems="center" flexDirection="column" w="100%">
            <Text
              fontSize={{
                base: "md",
                md: "lg",
                lg: "xl",
                xl: "2xl",
              }}
              color="black"
              fontWeight="700"
              mb="5px"
            >
              In line with the equally weighted votes
            </Text>
            {answers.length === 0 ? (
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg="brand.500"
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xl"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  No Votes Yet
                </Text>
              </Flex>
            ) : (
              <ReactApexChart
                options={pieChartOptions}
                series={pieChartData}
                type="pie"
                width="100%"
                // height="55%"
              />
            )}

            <Card
              bg={cardColor}
              flexDirection="row"
              justifyContent={"center"}
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              <SimpleGrid minChildWidth="120px" spacing="20px" w={"100%"}>
                {pieChartData.length > 0 && answers.length > 0 && (
                  <SimpleGrid
                    columns={{ base: 2, md: 2, lg: 2, xl: 2 }}
                    gap="10px"
                  >
                    {question.options.map((option, index) => (
                      <>
                        <Flex
                          direction="column"
                          alignItems={"center"}
                          py="5px"
                          key={
                            // eslint-disable-next-line react/no-array-index-key
                            option.title
                          }
                        >
                          <Flex align="center">
                            <Box
                              h="8px"
                              w="8px"
                              bg={pieChartOptions.colors[index]}
                              borderRadius="50%"
                              me="4px"
                            />
                            <Text
                              fontSize={textFontSize}
                              color="secondaryGray.600"
                              fontWeight="700"
                              mb="5px"
                            >
                              {option.title}
                            </Text>
                          </Flex>
                          <Text
                            fontSize={textFontSize}
                            color={textColor}
                            fontWeight="700"
                          >
                            {
                              // find the percentage of the option
                              "%" +
                                (
                                  (pieChartData[index] /
                                    // PieChart data values sum
                                    (pieChartData.reduce((a, b) => a + b, 0) ||
                                      1)) *
                                  100
                                ).toFixed(2) || 0
                            }
                          </Text>
                          {
                            // VOTE count
                            <Text
                              fontSize={{
                                base: "xs",
                                md: "md",
                                lg: "lg",
                                xl: "xl",
                              }}
                              color="secondaryGray.600"
                              mb="5px"
                            >
                              {pieChartData[index]} Votes
                            </Text>
                          }
                        </Flex>

                        {/* {index !== question.options.length - 1 && (
                      <VSeparator
                        mx={{ base: "30px", xl: "60px", "2xl": "60px" }}
                      />
                    )} */}
                      </>
                    ))}
                  </SimpleGrid>
                )}
              </SimpleGrid>
            </Card>
          </Card>
        )}
        {obedienceBasedIsLoading ? (
          <Flex
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
            <Text fontSize="xl" color="black" fontWeight="700" mb="5px">
              Loading...
            </Text>
          </Flex>
        ) : (
          <Card p="20px" alignItems="center" flexDirection="column" w="100%">
            <Text
              fontSize={{
                base: "md",
                md: "lg",
                lg: "xl",
                xl: "2xl",
              }}
              color="black"
              fontWeight="700"
              mb="5px"
            >
              In line with the voting rights of the Obedience
            </Text>
            {answers.length === 0 ? (
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg="brand.500"
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xl"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  No Votes Yet
                </Text>
              </Flex>
            ) : (
              <ReactApexChart
                options={pieChartOptions}
                series={obedienceBasedPieChartData}
                type="pie"
                width="100%"
                // height="55%"
              />
            )}
            <Card
              bg={cardColor}
              flexDirection="row"
              justifyContent={"center"}
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              <SimpleGrid minChildWidth="120px" spacing="20px" w={"100%"}>
                {pieChartData.length > 0 && answers.length > 0 && (
                  <SimpleGrid
                    columns={{ base: 2, md: 2, lg: 2, xl: 2 }}
                    gap="10px"
                  >
                    {question.options.map((option, index) => (
                      <>
                        <Flex
                          direction="column"
                          py="5px"
                          alignItems={"center"}
                          key={
                            // eslint-disable-next-line react/no-array-index-key
                            option.title
                          }
                        >
                          <Flex align="center">
                            <Box
                              h="8px"
                              w="8px"
                              bg={pieChartOptions.colors[index]}
                              borderRadius="50%"
                              me="4px"
                            />
                            <Text
                              fontSize={textFontSize}
                              color="secondaryGray.600"
                              fontWeight="700"
                              mb="5px"
                            >
                              {option.title}
                            </Text>
                          </Flex>
                          <Text
                            fontSize={textFontSize}
                            color={textColor}
                            fontWeight="700"
                          >
                            {
                              // find the percentage of the option
                              "%" +
                                (
                                  (obedienceBasedPieChartData[index] /
                                    obedienceBasedAnswers.reduce(
                                      (a, b) => a + b,
                                      0
                                    )) *
                                  100
                                ).toFixed(2) || 0
                            }
                          </Text>
                          {
                            // VOTE count
                            <Text
                              fontSize={{
                                base: "xs",
                                md: "md",
                                lg: "lg",
                                xl: "xl",
                              }}
                              color="secondaryGray.600"
                              mb="5px"
                            >
                              {obedienceBasedPieChartData[index]} Votes
                            </Text>
                          }
                        </Flex>

                        {/* {index !== question.options.length - 1 && (
                    <VSeparator
                      mx={{ base: "30px", xl: "60px", "2xl": "60px" }}
                    />
                  )} */}
                      </>
                    ))}
                  </SimpleGrid>
                )}
              </SimpleGrid>
            </Card>
          </Card>
        )}
      </SimpleGrid>
    </SimpleGrid>
  );
}

export default QuestionData;
