// Chakra imports
import { Box, Flex } from "@chakra-ui/react";
import { useContext } from "react";

// Custom components

// Assets
import banner from "../assets/img/auth/banner.png";
import avatar from "../assets/img/avatars/avatar4.png";
import Banner from "../components/Profile/Banner";
import { AuthContext } from "../services/AuthProvider";

export default function Profile() {
  const authContext = useContext(AuthContext);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex justifyContent={"center"}>
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={avatar}
          advancedUser={authContext.advancedUser!}
          name={authContext.advancedUser?.name || "Adela Parkson"}
          job={authContext.advancedUser?.mQuality || "No Role"}
          posts="17"
          followers="9.7k"
          following="274"
        />
      </Flex>
      {/* <Grid
        templateColumns={{
          base: "1.30fr 1.70fr",
          //   lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={avatar}
          name="Adela Parkson"
          job="Product Designer"
          posts="17"
          followers="9.7k"
          following="274"
        />
        <Storage gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} used={25.6} total={50} />
                <Upload
                    gridArea={{
                        base: '3 / 1 / 4 / 2',
                        lg: '1 / 3 / 2 / 4'
                    }}
                    minH={{ base: 'auto', lg: '420px', '2xl': '365px' }}
                    pe='20px'
                    pb={{ base: '100px', lg: '20px' }}
                />
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          pe="20px"
        />
      </Grid> */}
      {/* <Grid
        mb="20px"
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}
      >
        <Projects
                    banner={banner}
                    avatar={avatar}
                    name='Adela Parkson'
                    job='Product Designer'
                    posts='17'
                    followers='9.7k'
                    following='274'
                />

        
                <Notifications
                    used={25.6}
                    total={50}
                    gridArea={{
                        base: '3 / 1 / 4 / 2',
                        lg: '2 / 1 / 3 / 3',
                        '2xl': '1 / 3 / 2 / 4'
                    }}
                />
      </Grid> */}
    </Box>
  );
}
